<template>
  <div class="page-bookList common-page">
    <div class="page-header fx-vb">
      <div class="btn-act back" @click="pageBack">
      </div>
      <h1 class="page-title">
        Best Sellers
      </h1>
      <div class="btn-act"></div>
    </div>
    <div class="page-con">
      <div class="top-list common-book-list">
        <div class="one-book fx-vb" v-for="item of bookList" :key="item.id"  @click="jumpToBook(item.id)">
            <div class="cover">
              <img loading="lazy" :src="item.avatar" alt="">
            </div>
            <div class="detail">
              <h3>{{ item.name }}</h3>
              <p>{{ item.summary }}</p>
              <div class="info fx-vb">
                <div class="auther">
                  {{ item.author }}
                </div>
                <div class="score">
                  {{ item.scores / 10 }}
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
    <div class="load-more" ref="loadMore">
        <span v-if="noMore">no more</span>
        <span v-else>loading...</span>
      </div>
  </div>
</template>

<script>
import { apiCagegoryBookList } from '@/api';
import debounce from 'lodash/debounce';
export default {
  name: 'BookList',
  data() {
    return {
      bookList: [],
      page: 0,
      pageSize: 10,
      noMore: false,
      needReload: false,
    }
  },
  components: {
  },
  computed: {
  },
  methods: {
    jumpToBook(id) {
      this.$router.push({
        name: 'BookDetail',
        params: {
          id
        }
      })
    },
    pageBack() {
      this.$router.push({
        name: 'HomePage'
      })
      this.needReload = true;
      this.bookList = [];
      this.page = 0;
      this.noMore = false
    },
    async getBookList() {
      this.page += 1;
      const { code, data } = await apiCagegoryBookList({
        id: 3,
        page: this.page,
        size: this.pageSize
      })
      this.$toast.clear()
      if (code) return;
      if (!data.length || data.length < this.pageSize) {
        this.noMore = true;
      }
      const _data = data.map(item => {
        if(item.avatar.indexOf('http') === -1) {
          item.avatar = 'http://static.novellettes.com/' + item.avatar
        }
        return item 
      }) 
      console.log("_data",_data);
      this.bookList.push(..._data)
    },
    addScrollEvent() {
      const scrollHandler = debounce(() => {
        const moreLoad = this.$refs.loadMore;
        if (!moreLoad || this.noMore) return;
        const top = moreLoad.getBoundingClientRect().top
        if (top < document.body.clientHeight + 100) {
          this.getBookList()
        }
      }, 100);
      window.addEventListener('scroll', scrollHandler)
    },
  },
  mounted() {
    this.$toast.loading({
      duration: 0
    })
    this.getBookList();
    this.addScrollEvent()
  },
  activated() {
    if (this.needReload) {
      this.$toast.loading({
        duration: 0
      })
      this.getBookList();
      this.addScrollEvent()
      this.needReload = false
    }

  },
}
</script>

<style lang="less">
@import url("./index.less");
</style>